import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import logoDark from '../assets/images/logo-dark.png'
import logoLight from '../assets/images/logo-light.png'
import optracklogo from '../assets/images/optracklogo.png'
// import optracklogo from '../assets/images/latest-logo.png'
import optracklogowhite from '../assets/images/optracklogowhite.png'
// import newlogo from '../assets/images/newoptracklogo.png'
import newlogo from '../assets/images/latest-logo.png'

export default function Navbar({ navLight }) {
    let [toggle, setToggle] = useState(false);
    let [manu, setManu] = useState('');
    let [subManu, setSubManu] = useState('');
    let [scroll, setScroll] = useState(false)
    const [company_logo, setCompanyLogo] = useState(logoLight)


    const location = useLocation();

    useEffect(() => {
        const fetchSetting = async () => {
            const response = await fetch('/api/setting/')
            const json = await response.json();
            console.log(json);
            if (response.ok) {
                if (json)
                    setCompanyLogo(`/images/` + json.company_logo)


                // console.log(json);
            }

        }
        fetchSetting()
        window.scrollTo(0, 0);
        let current = location.pathname
        setManu(current)
        setSubManu(current)

        let windowScroll = () => {
            setScroll(window.scrollY > 50);
        }
        window.addEventListener('scroll', windowScroll)

        return () => {
            window.removeEventListener('scroll', windowScroll)
        }


    }, [location.pathname])
    return (
        <>
            <header id="topnav" className={`${scroll ? 'nav-sticky' : ''} defaultscroll sticky`}>
                <div className="container">
                    <div>
                        {navLight === true ?
                            <Link className="logo" to="/index-app">
                                <span className="logo-light-mode">
                                    <img src={newlogo} className="l-dark" alt="" style={{height:'40px',maxWidth:'100%',paddingRight:'5px',objectFit:'contain',overflow:'visible'}} />
                                    <img src={newlogo} className="l-light" alt="" style={{height:'40px',maxWidth:'100%',paddingRight:'5px',objectFit:'contain',overflow:'visible'}} />
                                </span>
                                <img src={optracklogo} className="logo-dark-mode" alt=""  />
                            </Link> :

                            <Link className="logo" to="/index-app">
                                <img src={newlogo} className="logo-light-mode" alt="" style={{height:'40px',maxWidth:'100%',paddingRight:'5px',objectFit:'contain',overflow:'visible'}} />
                                <img src={newlogo} className="logo-dark-mode" alt="" style={{height:'40px',maxWidth:'100%',paddingRight:'5px',objectFit:'contain',overflow:'visible'}} />
                            </Link>
                        }
                    </div>
                    {/* <div className="buy-button">
                    {navLight === true ? 
                        <Link to="#">
                            <div className="btn btn-primary login-btn-primary">Buy Now</div>
                            <div className="btn btn-light login-btn-light">Buy Now</div>
                        </Link> :
                        <Link to="#" className="btn btn-primary">Buy Now</Link>
                        }
                </div> */}
                    <div className="menu-extras">
                        <div className="menu-item">
                            <Link to="#" className={`${toggle ? 'open' : ''} navbar-toggle`} id="isToggle" onClick={() => setToggle(!toggle)} >
                                <div className="lines">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <div id="navigation" style={{ display: toggle ? 'block' : 'none' }}>
                        <ul className={`${navLight === true ? 'nav-light' : ''} navigation-menu nav-left`}>
                            <li className={manu === '/index-app' ? 'active' : ''}><Link to="/index-app" className={`sub-menu-item ${manu === '/index-app' ? 'active' : ''}`}>Home</Link></li>

                            {/* <li className={`has-submenu parent-parent-menu-item ${["/page-item","/page-about","/page-services", "/page-team", "/page-pricing", "/page-faqs", "/profile", "/profile-setting", "/profile-payment", "/billing-invoice", "/career-detail", "/career-apply", "/blogs", "/blog-detail", "/page-terms", "/page-privacy", "/page-comingsoon", "/page-maintenance", "/page-error", "/login", "/signup", "/forgot-password", "/thankyou"].includes(manu)? "active" : ""}`} ><Link to="#" onClick={(e)=>{setSubManu(subManu === "/page-item" ? "" : "/page-item")}}>Pages</Link><span className="menu-arrow"></span>
                            <ul className={`submenu ${["/page-item","/page-about","/page-services", "/page-team", "/page-pricing", "/page-faqs", "/profile", "/profile-setting", "/profile-payment", "/billing-invoice", "/career-detail", "/career-apply", "/blogs", "/blog-detail", "/page-terms", "/page-privacy", "/page-comingsoon", "/page-maintenance", "/page-error", "/login", "/signup", "/forgot-password", "/thankyou","/profile-item","/career-item", "/blog-item", "/term-item","/sepcial-item", "/auth-item"].includes(subManu)? "open" : ""}`}>

                                <li className={manu ===  '/page-about' ? 'active' : '' }><Link to="/page-about" className="sub-menu-item"> About Us</Link></li>
                                <li className={manu ===  '/page-services' ? 'active' : '' }><Link to="/page-services" className="sub-menu-item">Services</Link></li>
                                <li className={manu ===  '/page-team' ? 'active' : '' }><Link to="/page-team" className="sub-menu-item"> Team</Link></li>
                                <li className={manu ===  '/page-pricing' ? 'active' : '' }><Link to="/page-pricing" className="sub-menu-item">Pricing</Link></li>
                                <li className={manu ===  '/page-faqs' ? 'active' : '' }><Link to="/page-faqs" className="sub-menu-item">FAQs</Link></li>
                                
                                <li className={`${["/profile-item", "/profile", "/profile-setting", "/profile-payment", "/billing-invoice"].includes(manu) ? 'active' : ''} has-submenu parent-menu-item`}><Link to="#" onClick={(e)=>{setSubManu(subManu === "/profile-item" ? "" : "/profile-item")}}> Profile </Link><span className="submenu-arrow"></span>
                                    <ul className={`submenu ${["/profile-item", "/profile", "/profile-setting", "/profile-payment", "/billing-invoice"].includes(subManu)? "open" : ""}`}>
                                        <li className={manu ===  '/profile' ? 'active' : '' }><Link to="/profile" className="sub-menu-item">Profile</Link></li>
                                        <li className={manu ===  '/profile-setting' ? 'active' : '' }><Link to="/profile-setting" className="sub-menu-item">Setting</Link></li>
                                        <li className={manu ===  '/profile-payment' ? 'active' : '' }><Link to="/profile-payment" className="sub-menu-item">Payment</Link></li>
                                        <li className={manu ===  '/billing-invoice' ? 'active' : '' }><Link to="/billing-invoice" className="sub-menu-item">Invoice</Link></li>
                                    </ul>  
                                </li>

                                <li className={`has-submenu parent-menu-item ${["/career-item", "/career-detail","/career-apply"].includes(manu)? "active" : ""}`}><Link to="#" onClick={(e)=>{setSubManu(subManu === "/career-item" ? "" : "/career-item")}}> Career </Link><span className="submenu-arrow"></span>
                                    <ul className={`submenu ${["/career-item", "/career-detail","/career-apply"].includes(subManu)? "open" : ""}`}>
                                        <li className={manu ===  "/career-detail" ? 'active' : '' }><Link to="/career-detail" className="sub-menu-item">Career Detail</Link></li>
                                        <li className={manu ===  "/career-apply" ? 'active' : '' }><Link to="/career-apply" className="sub-menu-item">Career Apply</Link></li>
                                    </ul>  
                                </li>
                                <li className={`has-submenu parent-menu-item ${["/blog-item", "/blogs","/blog-detail"].includes(manu)? "active" : ""}`}><Link to="#" onClick={(e)=>{setSubManu(subManu === "/blog-item" ? "" : "/blog-item")}}> Blog </Link><span className="submenu-arrow"></span>
                                    <ul className={`submenu ${["/blog-item", "/blogs","/blog-detail"].includes(subManu)? "open" : ""}`}>
                                        <li className={manu ===  "/blogs" ? 'active' : '' }><Link to="/blogs" className="sub-menu-item">Blogs & News</Link></li>
                                        <li className={manu ===  "/blog-detail" ? 'active' : '' }><Link to="/blog-detail" className="sub-menu-item">Blog Detail</Link></li>
                                    </ul>  
                                </li>
                                <li className={`has-submenu parent-menu-item ${["/term-item", "/page-terms","/page-privacy"].includes(manu)? "active" : ""}`}><Link to="#" onClick={(e)=>{setSubManu(subManu === "/term-item" ? "" : "/term-item")}}> Utility </Link><span className="submenu-arrow"></span>
                                    <ul className={`submenu ${["/term-item", "/page-terms","/page-privacy"].includes(subManu)? "open" : ""}`}>
                                        <li className={manu ===  "/page-terms" ? 'active' : '' }><Link to="/page-terms" className="sub-menu-item">Terms of Services</Link></li>
                                        <li className={manu ===  "/page-privacy" ? 'active' : '' }><Link to="/page-privacy" className="sub-menu-item">Privacy Policy</Link></li>
                                    </ul>  
                                </li>
                                <li className={`has-submenu parent-menu-item ${["/sepcial-item", "/page-comingsoon","/page-maintenance", "/page-error"].includes(manu)? "active" : ""}`}><Link to="#" onClick={(e)=>{setSubManu(subManu === "/sepcial-item" ? "" : "/sepcial-item")}}> Special </Link><span className="submenu-arrow"></span>
                                    <ul className={`submenu ${["/sepcial-item", "/page-comingsoon","/page-maintenance", "/page-error"].includes(subManu)? "open" : ""}`}>
                                        <li><Link to="/page-comingsoon" className="sub-menu-item">Coming Soon</Link></li>
                                        <li><Link to="/page-maintenance" className="sub-menu-item">Maintenance</Link></li>
                                        <li><Link to="/page-error" className="sub-menu-item">Error</Link></li>
                                    </ul>
                                </li>
                                <li className={`has-submenu parent-menu-item ${["/auth-item", "/login","/signup", "/forgot-password","/thankyou"].includes(manu)? "active" : ""}`}><Link to="#" onClick={(e)=>{setSubManu(subManu === "/auth-item" ? "" : "/auth-item")}}> Authentication </Link><span className="submenu-arrow"></span>
                                    <ul className={`submenu ${["/auth-item", "/login","/signup", "/forgot-password","/thankyou"].includes(subManu)? "open" : ""}`}>
                                        <li><Link to="/login" className="sub-menu-item">Login</Link></li>
                                        <li><Link to="/signup" className="sub-menu-item">signup</Link></li>
                                        <li><Link to="/forgot-password" className="sub-menu-item">Forgot Password</Link></li>
                                        <li><Link to="/thankyou" className="sub-menu-item">Thank you</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li> */}


                            <li className={manu === "/page-about" ? 'active' : ''}><Link to="/page-about" className="sub-menu-item">About Us</Link></li>
                            <li className={manu === "/page-pricing" ? 'active' : ''}><Link to="/page-pricing" className="sub-menu-item">Pricing</Link></li>
                            {/* <li className={manu ===  "/page-services" ? 'active' : '' }><Link to="/page-services" className="sub-menu-item">Services</Link></li> */}
                            <li className={manu === "/page-faqs" ? 'active' : ''}><Link to="/page-faqs" className="sub-menu-item">Faqs</Link></li>

                            <li className={manu === "/contact" ? 'active' : ''}><Link to="/contact" className="sub-menu-item">Contact</Link></li>
                            <li className="mt-3">
                                <div className="buy-menu-btn sub-menu-item ">
                                    <Link to="https://dashboard.operationtrack.com/" className="btn btn-primary">Sign In</Link>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
            </header>
        </>
    )
}